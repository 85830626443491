import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "@src/api/config";
import { AppDispatch, RootState } from "@src/redux/store";
import {
  BankTransferStripeParams,
  BankTransferStripeResponse,
  CardChargeParams,
  IdParamsDetails,
  IdParams,
  ManualBankTransferParams,
  StripeCardPaymentParams,
  StripeCardPaymentResponse,
  UploadVoucherParams,
  UploadVoucherResponse
} from "./payment-references.types";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import SuccessToast from "@src/components/common/toast/SuccessToast";
import { ToastSuccess } from "@src/assets/images/icons";
import { DangerToast } from "@src/components/common/toast/ErrorToast";
import { showModalSuccess } from "../modalTypeSuccess";
import { setLoading, showModalDelete } from "../modalTypeDelete";
import { stopLoading } from "./slice";

export const createBankTransferStripe = createAsyncThunk<
  BankTransferStripeResponse,
  BankTransferStripeParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transfer/bankStripe", async (payload, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  const business_line = businessLines.businessLineSelected.id;
  const product = products.productSelected.value;
  const response = await api.post(`api/bank_transfer/stripe`, {
    ...payload,
    product,
    business_line
  });

  return {
    data: response.data
  };
});

export const createBankTransfer = createAsyncThunk<
  BankTransferStripeResponse,
  BankTransferStripeParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transfer/bank", async (payload, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  const business_line = businessLines.businessLineSelected.id;
  const product = products.productSelected.value;
  const response = await api.post(`api/bank_transfer`, {
    ...payload,
    product,
    business_line
  });
  return {
    data: response.data
  };
});

export const createStoreCharge = createAsyncThunk<
  BankTransferStripeResponse,
  BankTransferStripeParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("store/charge", async (payload, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  const business_line = businessLines.businessLineSelected.id;
  const product = products.productSelected.value;
  const response = await api.post(`api/store_charge`, {
    ...payload,
    product,
    business_line
  });
  return {
    data: response.data
  };
});

export const createUsdTransfer = createAsyncThunk<
  BankTransferStripeResponse,
  ManualBankTransferParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transfer/usd", async (payload, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  const business_line = businessLines.businessLineSelected.id;
  const product = products.productSelected.value;
  const response = await api.post(`api/manual_payment`, {
    ...payload,
    product,
    business_line
  });
  return {
    data: response.data
  };
});

export const createMxnTransfer = createAsyncThunk<
  BankTransferStripeResponse,
  ManualBankTransferParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transfer/mxn", async (payload, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines! as {
    businessLineSelected: { id: any };
  };
  const business_line = businessLines.businessLineSelected.id;
  const product = products.productSelected.value;
  const response = await api.post(`api/manual_payment`, {
    ...payload,
    product,
    business_line
  });
  return {
    data: response.data
  };
});

export const getDownloadRep = createAsyncThunk<
  BankTransferStripeResponse,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("download/rep", async (id) => {
  const response = await api.get(`api/payment_receipts/download/${id}`, {
    responseType: "blob"
  });
  fileDownload(response.data, `recibo_de_pago_${id}.pdf`);
  return {
    data: response.data
  };
});

export const getPreviewRep = createAsyncThunk<
  BankTransferStripeResponse,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("preview/rep", async (id) => {
  const response = await api.get(`api/payment_receipts/file/${id}`);
  return {
    data: response.data
  };
});

export const getPreviewRepByOpenpayId = createAsyncThunk<
  BankTransferStripeResponse,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("preview/rep/openpay", async (openpayId) => {
  const response = await api.get(
    `api/payment_receipts/payment_openpay/${openpayId}`
  );
  try {
    if (response?.data?.data?.url) {
      toast(
        <SuccessToast message={"Factura pagada con éxito"} submessage={null} />,
        {
          icon: <ToastSuccess />,
          hideProgressBar: true,
          className: "toast-success",
          autoClose: 5000
        }
      );
    } else {
      toast(
        <DangerToast
          message={
            response?.data?.message ??
            "No fue posible recuperar el recibo de pago"
          }
        />,
        {
          icon: null,
          hideProgressBar: true,
          className: "toast-danger",
          autoClose: 5000
        }
      );
    }
    return {
      data: response.data
    };
  } catch (err: any) {
    <DangerToast
      message={
        err.response.data?.message ??
        "No fue posible recuperar el recibo de pago"
      }
    />;
    return {
      data: null
    };
  }
});

export const createChargeWithCard = createAsyncThunk<
  BankTransferStripeResponse,
  CardChargeParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("charges/card", async (payload) => {
  const response = await api.post(`api/charges/card`, payload);
  if (response.data) {
    const url = response.data.redirect_url;
    location.href = url;
  }
  return {
    data: response.data
  };
});

export const getTransferAccount = createAsyncThunk<
  BankTransferStripeResponse,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transfer/card", async (payload) => {
  const response = await api.get(`api/transfer_account`, payload);
  return {
    data: response.data
  };
});

export const downloadTransferHandlerPDF = createAsyncThunk<
  void,
  any,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("transfer/download", async () => {
  const response = await api.get(`download/pdf/bank-transfer`);
  fileDownload(response.data, "transferencia_bancaria.pdf");
});

export const uploadVoucher = createAsyncThunk<
  UploadVoucherResponse,
  UploadVoucherParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("paymentReferences/uploadVoucher", async (payload, thunkApi) => {
  const { id, params, stepper } = payload;
  const { dispatch } = thunkApi;

  try {
    const response = await api.post(`api/voucher/upload/${id}`, {
      ...params
    });
    if (response.data.status === "success") {
      dispatch(
        showModalSuccess({
          message: response.data.message,
          action: stepper,
          url: null
        })
      );
    }
    return {
      data: response.data,
      params
    };
  } catch (err) {
    console.error("Error uploadVoucher", err);
    toast(<DangerToast message={"Error cargar los archivos"} />, {
      icon: null,
      hideProgressBar: true,
      className: "toast-danger",
      autoClose: 5000
    });
    return {
      data: null,
      params: null
    };
  }
});

export const getVouchersUrl = createAsyncThunk<
  BankTransferStripeResponse,
  IdParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("vouchers/show", async ({ id }) => {
  try {
    const response = await api.get(`api/show_vouchers/${id}`);
    return {
      data: response.data
    };
  } catch (err) {
    return {
      data: null
    };
  }
});

export const getPayment = createAsyncThunk<
  BankTransferStripeResponse,
  IdParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("payment/getById", async ({ id }) => {
  try {
    const response = await api.get(`api/payments/${id}`);
    return {
      data: response.data
    };
  } catch (err) {
    return {
      data: null
    };
  }
});

export const approveManualPayment = createAsyncThunk<
  BankTransferStripeResponse,
  IdParamsDetails,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("payment/approve", async (payload, thunkApi) => {
  const { id, params } = payload;
  const { dispatch } = thunkApi;
  try {
    const response = await api.post(`api/manual_payment/approve/${id}`, params);
    if (response.data.status === "success") {
      if (response.data.data.invoices[0]?.uncollectible_at) {
        dispatch(
          showModalSuccess({
            message: response.data.message,
            url: `/payments`
          })
        );
      } else {
        const payment_id = response.data.data.receipt.id;
        dispatch(
          showModalSuccess({
            message: response.data.message,
            url: `/payments/show/${payment_id}`
          })
        );
      }
    } else {
      dispatch(
        showModalDelete({ message: response.data.message, autoClose: true })
      );
    }
    dispatch(stopLoading());
    return {
      data: response.data
    };
  } catch (err: any) {
    dispatch(stopLoading());
    const message =
      err.response.data?.message ?? "No fue posible conciliar la factura";
    dispatch(showModalDelete({ message, autoClose: true }));
    new Error(message ?? "No fue posible conciliar la factura");
    return {
      data: null
    };
  }
});

export const rejectManualPayment = createAsyncThunk<
  BankTransferStripeResponse,
  IdParamsDetails,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("payment/reject", async (payload, thunkApi) => {
  const { id, params } = payload;
  const { dispatch } = thunkApi;
  try {
    const response = await api.post(`api/manual_payment/reject/${id}`, params);
    if (response.data.status === "success") {
      dispatch(
        showModalSuccess({ message: response.data.message, url: `/payments` })
      );
    }
    return {
      data: response.data
    };
  } catch (err) {
    return {
      data: null
    };
  }
});

export const stripeCardPayment = createAsyncThunk<
  StripeCardPaymentResponse,
  StripeCardPaymentParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("invoices/stripeCardPayment", async (params, thunkApi) => {
  const { dispatch } = thunkApi;
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  const businessLines = state.businessLines as {
    businessLineSelected: { id: any };
  };
  const business_line = businessLines.businessLineSelected.id;
  try {
    const response = await api.post(`api/charges/stripe-card`, {
      ...params,
      product,
      business_line
    });
    dispatch(setLoading(false));
    return {
      data: response.data.data.payment
    };
  } catch (err: any) {
    dispatch(setLoading(false));
    toast(
      <DangerToast
        message={
          err.response.data?.message ??
          "No fue posible realizar el pago con la tarjeta seleccionada"
        }
      />,
      { hideProgressBar: true, className: "toast-danger", autoClose: 5000 }
    );
    throw new Error(
      err.response.data?.message ??
        "No fue posible realizar el pago con la tarjeta seleccionada"
    );
  }
});
