// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import {
  showModalSuccess,
  hideModalSuccess
} from "../../../../redux/modalTypeSuccess"
import { showModalError } from "../../../../redux/modalTypeError"
import { hideModalCustom } from "../../../../redux/modalTypeCustom"
import ErrorToast from "../../../../components/common/toast/ErrorToast"
import { toast } from "react-toastify"
import SuccessToast from "../../../../components/common/toast/SuccessToast"
import { ToastSuccess } from "../../../../assets/images/icons"
import { setLoading, setNotLoading } from "../../../../redux/response"
import { getMe } from "@src/redux/auth"

const urlBase = "api/clients"

export const getPlans = createAsyncThunk(
  "clients/getPlans",
  async (params = {}) => {
    const response = await api.get(`/api/plans`, { params })
    return {
      data: response.data
    }
  }
)

export const getClients = createAsyncThunk(
  "clients/get",
  async ({ params = null }, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines

    params.product = productSelected.value
    params.business_line = businessLineSelected.id

    const response = await api.get(`${urlBase}`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getClientById = createAsyncThunk("clients/getById", async (id) => {
  const response = await api.get(`${urlBase}/${id}`)
  return {
    data: response.data
  }
})

export const createInfoClient = createAsyncThunk(
  "createInfoClient",
  async ({ data, url }, { dispatch }) => {
    try {
      const response = await api.post(`api/clients`, data)
      dispatch(setNotLoading())
      if (response.data.status === "success") {
        dispatch(showModalSuccess({ message: response.data.message, url }))

        if (localStorage.getItem("newClientID") === "0") {
          localStorage.setItem(
            "newClientID",
            response.data.data.client.role_id // save on localStorage, this will be get registered client on Certs
          )
        }

        setTimeout(() => dispatch(hideModalSuccess()), 3000)
      }
      return {
        data: response.data
      }
    } catch (err) {
      dispatch(setNotLoading())
      dispatch(
        showModalError({
          message:
            err.response.data?.message ?? "No fue posible crear el cliente"
        })
      )
      throw new Error(
        err.response.data?.message ?? "No fue posible crear el cliente"
      )
    }
  }
)

export const registerClient = createAsyncThunk(
  "registerClient",
  async ({ data, url }, { dispatch }) => {
    try {
      const response = await api.post(`api/clients/signup`, data)
      dispatch(setNotLoading())

      if (response.data.status === "success") {
        dispatch(showModalSuccess({ message: response.data.message, url }))

        setTimeout(() => dispatch(hideModalSuccess()), 3000)
      }

      return {
        data: response.data
      }
    } catch (err) {
      dispatch(setNotLoading())
      dispatch(
        showModalError({
          message: err.response.data?.message ?? "No fue posible registrarse"
        })
      )

      throw new Error(err.response.data?.message ?? "No fue posible registarse")
    }
  }
)

export const updateInfoClient = createAsyncThunk(
  "updateInfoClient",
  /**
   *
   * @param {Object} params
   * @param {string} params.id
   * @param {Object} params.data
   * @param {string} params.url
   * @param {Object} options
   * @returns
   */
  async ({ id, data, url }, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const response = await api.put(`api/clients/${id}`, data)
      dispatch(setNotLoading())
      if (response.data.status === "success") {
        dispatch(showModalSuccess({ message: response.data.message, url }))
        dispatch(getClients({ page: 1, filter: "active", type: "nombre" }))
        dispatch(getMe())
      }
      return {
        data: response.data
      }
    } catch (err) {
      dispatch(setNotLoading())
      console.log("Error:", err)
      toast(<ErrorToast message={"Error al actualizar al cliente"} />, {
        icon: null,
        hideProgressBar: true,
        className: "toast-danger",
        autoClose: 5000
      })
    }
  }
)

export const getInfoClientById = createAsyncThunk(
  "getInfoClientById",
  async (id) => {
    const response = await api.get(`api/clients/${id}`)
    return {
      data: response.data
    }
  }
)

export const getScheduledCertificates = createAsyncThunk(
  "getScheduledCertificates",
  /**
   *
   * @param {string} clientId
   * @returns {Object}
   */
  async (clientId) => {
    const response = await api.get(`api/certificates/scheduled/${clientId}`)
    return {
      data: response.data
    }
  }
)

export const getPreferencesClient = createAsyncThunk(
  "clients/getPreferencesClient",
  async ({ clientId, productId, businessLineId, toggleHandler }) => {
    const response = await api.get(
      `api/preferences?client_id=${clientId}&product_id=${productId}&business_line=${businessLineId}`
    )
    if (response.data.status === "success") {
      // hide confirmation modal
      toggleHandler()
      // show success
      // dispatch(showModalSuccess({ message: response.data.message }))
    }
    // dispatch(getClientById(id))
    return {
      data: response.data
    }
  }
)

export const getPreferencesSeller = createAsyncThunk(
  "sellers/getPreferencesSeller",
  /**
   *
   * @param {object} params
   * @param {number} params.userId
   * @param {object} params.toggleHandler
   * @returns
   */
  async ({ userId, toggleHandler }) => {
    const response = await api.get(`api/preferences/sellers/${userId}`)
    if (response.data.status === "success") {
      // hide confirmation modal
      toggleHandler()
      // show success
      // dispatch(showModalSuccess({ message: response.data.message }))
    }
    // dispatch(getClientById(id))
    return {
      data: response.data
    }
  }
)

export const getDateBilling = createAsyncThunk(
  "calcPossibleBillingDate",
  async ({ toggleHandler }) => {
    const response = await api.get(`api/calcPossibleBillingDate`)

    if (response.data.status === "success") {
      // hide confirmation modal
      toggleHandler()
      // show success
      // dispatch(showModalSuccess({ message: response.data.message }))
    }
    // dispatch(getClientById(id))
    return {
      data: response.data
    }
  }
)

export const createManagerNotification = createAsyncThunk(
  "clients/createManagerNotification",
  async ({ id, data }, { dispatch }) => {
    const response = await api.post(`api/managers`, data)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
    }
    dispatch(getClientById(id))
    return {
      data: response.data
    }
  }
)

export const enableClient = createAsyncThunk(
  "clients/enable",
  async ({ id }, { dispatch }) => {
    const response = await api.patch(`api/active/clients/${id}`)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
      dispatch(getClientById(response.data.data.role_id))
      dispatch(hideModalCustom())
    }
    return {
      data: response.data
    }
  }
)

export const disableClient = createAsyncThunk(
  "clients/disable",
  async ({ id }, { dispatch }) => {
    const response = await api.delete(`api/clients/${id}`)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
      dispatch(hideModalCustom())
    }
    dispatch(getClientById(id))
    return {
      data: response.data
    }
  }
)

export const editManagerNotification = createAsyncThunk(
  "clients/editManagerNotification",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`api/managers/${id}`, data)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
    }
    dispatch(getClientById(response.data.data.client_id))
    return {
      data: response.data
    }
  }
)

export const deleteManagerNotification = createAsyncThunk(
  "clients/deleteManagerNotification",
  async (id, { dispatch }) => {
    const response = await api.delete(`api/managers/${id}`)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
    }
    dispatch(getClientById(response.data.data.client_id))
    return {
      data: response.data
    }
  }
)

export const getTaxDataByClient = createAsyncThunk(
  "getTaxDataByClient",
  /**
  * @param {Object} allParams
  */
  async ({ id, params }, { getState }) => {
    const state = getState()
    const product = state.products?.productSelected?.value
    const business_line = state.businessLines?.businessLineSelected?.id
    const response = await api.get(`api/clients/taxt_data/${id}`, {
      params: { ...params, product, business_line }
    })
    return {
      params,
      data: response.data
    }
  }
)

export const getTaxDataById = createAsyncThunk(
  "taxdata/getById",
  async (id) => {
    const response = await api.get(`api/taxdata/${id}`)
    return {
      data: response.data
    }
  }
)

export const getBillDataByClient = createAsyncThunk(
  "getBillDataByClient",
  async (id) => {
    const response = await api.get(`api/clients/billing/${id}`)

    return {
      data: response.data
    }
  }
)

export const getFeesClient = createAsyncThunk(
  "getFeesClient",
  async ({ id, params }) => {
    const response = await api.get(`api/clients/fees/${id}`, { params })
    return {
      data: response.data
    }
  }
)

export const deleteBusinessName = createAsyncThunk(
  "clients/businessName/delete",
  async ({ id, url }, { dispatch }) => {
    const response = await api.delete(`api/clients/business_name/${id}`)
    const clientId = response.data.data.client_id
    //const url = `/users/clients/show/${clientId}`
    dispatch(showModalSuccess({ message: response.data.message, url }))
    dispatch(getClientById(clientId))
    const params = { page: 1 }
    dispatch(getTaxDataByClient({ id: clientId, params }))
    return {
      data: response.data
    }
  }
)

export const sendActivationCodeByEmail = createAsyncThunk(
  "client/sendByEmail",
  async ({ client_id, params }) => {
    try {
      const response = await api.post(`api/clients/send_mail/${client_id}`, {
        ...params
      })
      // console.log(response)
      toast(<SuccessToast message={response.data.message} />, {
        icon: <ToastSuccess />,
        hideProgressBar: true,
        className: "toast-success",
        autoClose: 5000
      })
      return {
        data: response.data,
        params
      }
    } catch (err) {
      toast(<ErrorToast message={"Error al enviar el correo"} />, {
        icon: null,
        hideProgressBar: true,
        className: "toast-danger",
        autoClose: 5000
      })
    }
  }
)

export const getBankData = createAsyncThunk("getBankData", async (id) => {
  const response = await api.get(`api/bankData/${id}`)

  return {
    data: response.data
  }
})

export const getEmployees = createAsyncThunk(
  "getEmployees",
  async ({ id, params = null }) => {
    const response = await api.get(`api/clients/employee/${id}`, { params })
    return {
      data: response.data
    }
  }
)

export const getEmployeesByTaxData = createAsyncThunk(
  "getEmployeeByTaxData",
  async (allParams) => {
    const { taxData, params } = allParams
    const response = await api.get(`api/tax_data/employees/${taxData}`, {
      params
    })
    return {
      data: response.data
    }
  }
)

export const createEmployee = createAsyncThunk(
  "clients/createEmployee",
  async ({ id, data }, { dispatch }) => {
    const response = await api.post(`api/clients/employee`, data)

    dispatch(getClientById(id))
    dispatch(getEmployees({ id }))

    return {
      data: response.data
    }
  }
)

export const editEmployee = createAsyncThunk(
  "clients/editEmployee",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`api/clients/employee/${id}`, data)

    dispatch(getClientById(response.data.data.client.id))
    dispatch(getEmployees({ id: response.data.data.client.id }))
    return {
      data: response.data
    }
  }
)

export const editEnsuredEmployee = createAsyncThunk(
  "clients/editEnsuredEmployee",
  async ({ id, data }) => {
    const response = await api.put(`api/certificates/employee/${id}`, data)

    return {
      data: response.data
    }
  }
)

export const deleteEmployee = createAsyncThunk(
  "clients/deleteEmployee",
  async (id, { dispatch }) => {
    const response = await api.delete(`api/clients/employee/${id}`)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
    }
    dispatch(getClientById(response.data.data.client_id))
    dispatch(getEmployees({ id: response.data.data.client_id }))
    return {
      data: response.data
    }
  }
)

export const restoreEmployee = createAsyncThunk(
  "clients/restoreEmployee",
  async (id, { dispatch }) => {
    const response = await api.patch(`api/active/clients/employee/${id}`)
    if (response.data.status === "success") {
      dispatch(showModalSuccess({ message: response.data.message }))
    }
    dispatch(getClientById(response.data.data.client.id))
    dispatch(getEmployees({ id: response.data.data.client.id }))
    return {
      data: response.data
    }
  }
)

export const getProductsById = createAsyncThunk(
  "getProductsById",
  async (id) => {
    const response = await api.get(`api/clients/products/${id}`)
    return {
      data: response.data
    }
  }
)

export const clientsSlice = createSlice({
  name: "clients",
  initialState: {
    preferencesClient: null,
    preferencesSeller: null,
    dateBlling: null,
    // paginated
    allClients: [],
    options: [],
    taxDataOptions: [],
    // selected
    client: [],
    seller: [],
    taxData: [],
    fees: {},
    bankData: null,
    managers: [],
    employees: [],
    // pagination meta
    meta: {},
    total: 0,
    message: "",
    status: {},
    defaultValues: null,
    saved: false,
    errorCreateClient: null,
    errorRegisterClient: null,
    certificates: null,
    scheduledStatus: "idle",
    plans: [],
    productsByClient: null,
    loadingPlans: false,
    sending: false,
    clientSelected: null
  },
  reducers: {
    setClientSelected: (state, action) => {
      state.clientSelected = action.payload
    },
    clearClientSelected: (state) => {
      state.clientSelected = null
    },
    resetClients: (state) => {
      state.allClients = []
      state.client = []
      state.seller = []
      state.taxData = []
      state.fees = {}
      state.bankData = null
      state.managers = []
      state.meta = {}
      state.total = 0
      state.message = ""
      state.status = {}
      state.defaultValues = null
      state.errorCreateClient = null
      state.plans = []
      state.options = []
    },
    clearSaved: (state) => {
      state.saved = false
    },
    clearTaxOptions: (state) => {
      state.taxDataOptions = []
    },
    clearErrorCreateClient: (state) => {
      state.errorCreateClient = null
    },
    clearPlans: (state) => {
      state.plans = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClientById.fulfilled, (state, action) => {
      const payload = action.payload.data.hasOwnProperty("data")
        ? action.payload.data.data
        : action.payload.data
      state.client = payload
      state.seller = payload.seller
      // state.taxData = payload.data.taxData
      state.managers = payload.managers
      state.defaultValues = {
        nombres: payload.name,
        correo_electronico: payload.email,
        telefono: payload.phone,
        vendedor: payload.seller?.id,
        tipo_de_industria: payload.type_of_industry,
        tipo_facturacion: payload.billing_type,
        periodo_corte: payload.billing_period,
        dia_corte: payload.billing_day,
        contacto_nombres: payload?.contact_name,
        apellido_paterno: payload?.last_name,
        forma_pago: payload?.payment_form_mxn,
        apellido_materno: payload?.last_name_2,
        tipos_cliente: payload?.client_type,
        confirmation: true,
        electronic_equipment: false,
        personal_accidents: false,
        cargo: false,
        civil_liability: false,
        frequency_of_issuance: payload?.frequency_of_issuance,
        contact_position: payload?.contact_position,
        key_features: payload?.key_features
      }
    })
    builder.addCase(createManagerNotification.fulfilled, (state, action) => {
      const payload = action.payload.data
      state.managers = payload
    })
    builder.addCase(deleteBusinessName.fulfilled, (state, action) => {
      const payload = action.payload
      state.message = payload.message
      state.status = payload.status
    })
    builder.addCase(getInfoClientById.fulfilled, (state, action) => {
      const payload = action.payload.data.data
      // armado de json de back a front
      state.defaultValues = {
        id: payload.id,
        nombres: payload.name,
        forma_pago: Boolean(payload?.payment_form_mxn),
        correo_electronico: payload.email,
        telefono: `${payload.phone}`,
        vendedor: payload.seller.id,
        tipo_de_industria: payload.type_of_industry,
        type_of_industry_option: payload.type_of_industry_option,
        taxData: payload.taxData,
        tipo_facturacion: payload.billing_type,
        periodo_corte: payload.billing_period,
        dia_corte: payload.billing_day,
        contacto_nombres: payload?.contact_name,
        apellido_paterno: payload?.last_name,
        apellido_materno: payload?.last_name_2,
        confirmation: true,
        products: payload?.products,
        tipos_cliente: payload?.client_type,
        electronic_equipment: false,
        personal_accidents: false,
        cargo: false,
        civil_liability: false,
        frequency_of_issuance: payload?.frequency_of_issuance,
        contact_position: payload?.contact_position,
        key_features: payload?.key_features
      }
      payload?.products
        ?.filter((product) => product.id === 2)
        ?.map((product) => {
          state.defaultValues[
            `cuotaBase_${product?.pivot?.project_profile_id}`
          ] = product?.pivot?.base_fee
          state.defaultValues[
            `cuotaVenta_${product?.pivot?.project_profile_id}`
          ] = product?.pivot?.sales_fee
        })
    })
    builder.addCase(getTaxDataByClient.fulfilled, (state, action) => {
      if (action.payload.params?.format === "options") {
        state.taxDataOptions =
          action.payload?.data?.length > 0 ? action.payload?.data : []
      } else {
        state.taxData = action.payload.data
        state.total = action.payload.data.meta.total
      }
    })
    builder.addCase(getBillDataByClient.fulfilled, (state, action) => {
      state.billData = action.payload.data
    })
    builder.addCase(getFeesClient.fulfilled, (state, action) => {
      state.fees = action.payload.data.data
    })
    builder.addCase(getClients.fulfilled, (state, action) => {
      if (action.payload.params?.format === "options") {
        state.options = action.payload.data
      } else {
        state.allClients = action.payload.data.data
      }
    })
    builder.addCase(getPlans.pending, (state) => {
      state.loadingPlans = true
    })
    builder.addCase(getPlans.rejected, (state) => {
      state.loadingPlans = false
    })
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.loadingPlans = false
      state.plans = action.payload.data.data
    })
    builder.addCase(getTaxDataById.fulfilled, (state, action) => {
      state.taxData = action.payload.data.data[0]
    })
    builder.addCase(getBankData.fulfilled, (state, action) => {
      state.bankData = action.payload.data
    })
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.employees = action.payload.data
    })
    builder.addCase(getEmployeesByTaxData.fulfilled, (state, action) => {
      state.employees = action.payload.data.data
    })
    builder.addCase(editEmployee.pending, (state) => {
      state.sending = true
    })
    builder.addCase(editEmployee.fulfilled, (state) => {
      state.sending = false
    })
    builder.addCase(editEmployee.rejected, (state) => {
      state.sending = false
    })
    builder.addCase(createEmployee.pending, (state) => {
      state.sending = true
    })
    builder.addCase(createEmployee.rejected, (state) => {
      state.sending = false
    })
    builder.addCase(createEmployee.fulfilled, (state) => {
      state.sending = false
    })
    builder.addCase(updateInfoClient.fulfilled, (state) => {
      state.saved = true
    })
    builder.addCase(createInfoClient.rejected, (state, action) => {
      state.saved = true
      state.errorCreateClient = action.error?.message
    })
    builder.addCase(registerClient.rejected, (state, action) => {
      state.saved = true
      state.errorRegisterClient = action.error?.message
    })
    builder.addCase(getScheduledCertificates.fulfilled, (state, action) => {
      state.certificates = action.payload?.data?.data
    })
    builder.addCase(getProductsById.fulfilled, (state, action) => {
      state.productsByClient = action.payload.data
    })
    builder.addCase(getPreferencesClient.fulfilled, (state, action) => {
      state.preferencesClient = action.payload.data
    })
    builder.addCase(getPreferencesClient.rejected, (state) => {
      state.preferencesClient = null
    })
    builder.addCase(getPreferencesSeller.fulfilled, (state, action) => {
      state.preferencesSeller = action.payload.data
    })
    builder.addCase(getPreferencesSeller.rejected, (state) => {
      state.preferencesSeller = null
    })
    builder.addCase(getDateBilling.fulfilled, (state, action) => {
      state.dateBlling = action.payload.data
    })
    builder.addCase(getDateBilling.rejected, (state) => {
      state.dateBlling = null
    })
  }
})

export const {
  resetClients,
  clearSaved,
  clearTaxOptions,
  clearErrorCreateClient,
  clearPlans,
  setClientSelected,
  clearClientSelected
} = clientsSlice.actions
export default clientsSlice.reducer
